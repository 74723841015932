import { Item } from '@/api';
import clsx from 'clsx';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Link from 'next/link';

const AuthCode = ({ xl, children, isLoading, type, id, noToolTip = false }: { xl?: boolean; children: string, isLoading?: boolean, type?: Item.AuthenticationTypeEnum, id: string, noToolTip?: boolean }) => {
    if (isLoading) {
        return <Skeleton width={110} height={30} borderRadius={15} />;
    }

    if (!type) {
        return null;
    }

    return (
        <>
            <div
                className={clsx('flex flex-row  items-center bg-gradient-code-badge w-fit font-[500] rounded-full', xl ? 'text-[20px] py-[0.2rem] pl-1 pr-3' : 'text-[12px] py-[0.1rem] px-2', {
                    'bg-gradient-code-silver': type === Item.AuthenticationTypeEnum.Silver,
                    'bg-gradient-code-gold': type === Item.AuthenticationTypeEnum.Gold
                })}
                data-tooltip-id={`tooltip-auth-code-${id}`}>
                <img
                    src={xl ? '/assets/general/realest-emblem.svg' : '/assets/icons/tr-icon-small.png'}
                    className={xl ? 'w-[50px] h-[50px]' : 'w-[16px] h-[16px]'}
                    alt="tr-id"
                />
                <span className="mx-2">{children}</span>
            </div>
            {!noToolTip &&
                <ReactTooltip
                    id={`tooltip-auth-code-${id}`}
                    place="top"
                    clickable
                    className="max-w-[80%]"
                >
                    <span>{type === Item.AuthenticationTypeEnum.Gold ? i18next.t('auth-code.gold') : i18next.t('auth-code.silver')}</span>
                    {' '}<Link href="/authentication" className="underline" target="_blank">{i18next.t('button-text.learn-more')}</Link>
                </ReactTooltip>
            }
        </>
    );
};

export default AuthCode;
