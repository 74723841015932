import { Auction, AuctionBid, BidDto, Item } from '@/api';
import AuctionManager from '@/helpers/AuctionManager';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import { useAlert } from '@/providers/AlertProvider';
import {
    bidOnAuctionQueryFn,
    checkAuctionAccessFn,
    getlastBidFn,
} from '@/queries/auction';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import useAuth from '@/contexts/AuthContext';
import { BaseException } from '@/helpers/Exceptions';
import useSocket from './api/useSocket';

export type ProductAuctionState = 'no_auction' | null;

const AuctionSocketSubscriptions: { [key: string]: number } = {};

const useAuction = (
    prod?: Item,
    overWriteUserBid?: AuctionBid,
    isWatching = false
) => {
    const queryClient = useQueryClient();
    const { user } = useAuth();
    const { show } = useAlert();
    const socket = useSocket();
    const [auction, setAuction] = useState<Auction | undefined>(prod ? prod.current_auction : undefined);

    useEffect(() => {
        setAuction(prod?.current_auction);
    }, [prod]);

    const { data: lastBid } = useQuery({
        queryKey: ['getlastBidFn', auction?.id],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getlastBidFn(auction!.id),
        enabled:
            !!auction &&
            !!user &&
            auction.type === Auction.TypeEnum.Auction &&
            [
                Auction.StatusEnum.Blind,
                Auction.StatusEnum.Extended,
                Auction.StatusEnum.Open,
            ].includes(auction.status),
        refetchOnWindowFocus: isWatching,
    });

    const { data: auctionAccess } = useQuery({
        queryKey: ['checkAuctionAccessFn', auction?.id],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => checkAuctionAccessFn(auction!.id),
        enabled:
            !!auction &&
            !!user &&
            auction.type === Auction.TypeEnum.Auction &&
            [Auction.StatusEnum.Blind, Auction.StatusEnum.Extended].includes(auction.status),
        refetchOnWindowFocus: isWatching,
    });

    const isOwner = useMemo(() => prod?.owner_id === user?.id, [prod?.owner_id, user?.id]);

    const hasUserAuctionAccess = useMemo(() => {
        if (!user) {
            return false;
        }
        if (!auction) {
            return false;
        }
        if (isOwner) {
            return false;
        }
        if (auction.status === Auction.StatusEnum.Open) {
            return true;
        }
        return auctionAccess?.status === 'allowed';
    }, [auction, auctionAccess?.status, user, isOwner]);

    const endTime = useMemo(() => {
        if (!auction) {
            return undefined;
        }
        if (auction?.status === Auction.StatusEnum.Closed || auction?.status === Auction.StatusEnum.Done) {
            return undefined;
        }
        if (auction?.status === Auction.StatusEnum.Blind) {
            return new Date(auction.blind_bid_end_date);
        }
        if (auction?.status === Auction.StatusEnum.Extended) {
            return new Date(auction.extended_bidding_end_date);
        }
        return new Date(auction?.end_date);
    }, [auction]);

    const comingSoon = useMemo(() => {
        if (auction?.status === Auction.StatusEnum.NotStarted) {
            return {
                startTime: new Date(auction.start_date)
            };
        }
        return undefined;
    }, [auction]);

    const lastUserBid = lastBid || overWriteUserBid;

    const auctionOver = auction?.status === Auction.StatusEnum.Closed || auction?.status === Auction.StatusEnum.Done;

    useEffect(() => {
        if (auction && auction.type === Auction.TypeEnum.Auction) {
            const auctionListener = (_auction: Auction) => {
                if (auction?.id === _auction.id) {
                    setAuction(_auction);
                    queryClient.invalidateQueries({
                        predicate: (query) => query.queryKey[0] === 'getAuctionBidHistoryQueryFn'
                    });
                }
            };
            const listenerToken = AuctionManager.getInstance().addAuctionStateListener(auction, auctionListener);
            return () => {
                AuctionManager.getInstance().removeAuctionStateListener(auction, listenerToken);
            };
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auction?.id]);

    useEffect(() => {
        if (
            auction?.id &&
            auction?.status !== Auction.StatusEnum.Done &&
            auction.type === Auction.TypeEnum.Auction
        ) {
            AuctionSocketSubscriptions[auction.id] =
                AuctionSocketSubscriptions[auction.id] || 0;
            AuctionSocketSubscriptions[auction.id] += 1;
            if (AuctionSocketSubscriptions[auction.id] === 1) {
                socket?.emit('auction/subscribe', { auction_id: auction.id });
            }
            return () => {
                if (AuctionSocketSubscriptions[auction.id]) {
                    AuctionSocketSubscriptions[auction.id] -= 1;
                    if (AuctionSocketSubscriptions[auction.id] <= 0) {
                        socket?.emit('auction/unsubscribe', {
                            auction_id: auction.id,
                        });
                    }
                }
            };
        }
    }, [socket, auction?.id, auction?.status, auction?.type]);

    const placeBidMutation = useMutation({
        mutationFn: bidOnAuctionQueryFn,
        onSuccess: (response) => {
            if (response) {
                AuctionManager.getInstance().updateAuction(response.auction);
                queryClient.setQueryData(['getlastBidFn', auction?.id], () => {
                    return response.bid;
                });
                queryClient.invalidateQueries({
                    predicate: (query) => query.queryKey[0] === 'getAuctionBidHistoryQueryFn'
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any).dataLayer) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).dataLayer.push({
                        event: 'bid',
                        name: prod?.name,
                        amount: parseFloat(response.bid.max_amount || response.bid.amount),
                        currency: 'USD'
                    });
                }
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(
                error as Request
            );
            if (ErrorHandler.Auth.emailNotVerified(transformedError)) {
                // Handled in root
            } else {
                show({
                    buttonText: 'Okay',
                    description: (transformedError as BaseException).message,
                    showButton: true,
                    text: 'Bidding Failed!',
                    animation: 'AlertAnimation',
                });
            }
        },
    });

    const hasAuction = auction !== null && auction !== undefined && comingSoon === undefined;

    const suggestedBid = useMemo(() => {
        if (lastUserBid?.max_amount && auction?.min_next_bid) {
            if (parseFloat(lastUserBid.max_amount) >= parseFloat(auction?.min_next_bid || '0.00')) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const nextSuggestion = auction?.min_next_bids.find((bid) => parseFloat(bid) > parseFloat(lastUserBid.max_amount!));
                if (nextSuggestion) {
                    return nextSuggestion;
                }
                return auction?.min_next_bids[auction?.min_next_bids.length - 1];
            }
        }
        return auction?.min_next_bid || '0.00';
    }, [auction?.min_next_bid, auction?.min_next_bids, lastUserBid?.max_amount]);

    const placeBid = (
        amount: string,
        payment_method?: string,
        payment_method_type?: BidDto.PaymentMethodTypeEnum
    ) => {
        return placeBidMutation.mutateAsync({
            auctionId: auction?.id ?? '',
            data: { amount, payment_method, payment_method_type },
        });
    };

    const isWinning = useMemo(() => {
        if (auction?.type !== Auction.TypeEnum.Auction) {
            return undefined;
        }
        if (auction?.status === Auction.StatusEnum.Blind) {
            return undefined;
        }
        if (lastUserBid && auction) {
            return auction.current_bid?.user_id === user?.id || lastUserBid.id === auction.current_bid_id;
        }
        if (auction && auction.current_bid) {
            return auction.current_bid.user_id === user?.id;
        }
        return undefined;
    }, [auction, lastUserBid, user]);

    const isLoosing = useMemo(() => {
        if (isWinning) {
            return false;
        }
        if (auction?.type !== Auction.TypeEnum.Auction) {
            return undefined;
        }
        if (auction?.status === Auction.StatusEnum.Blind) {
            return undefined;
        }
        if (lastUserBid && auction) {
            return lastUserBid.id !== auction.current_bid_id;
        }
        return undefined;
    }, [auction, isWinning, lastUserBid]);

    const hasWonAuction = useMemo(() => {
        if (auction?.status === Auction.StatusEnum.Done) {
            return isWinning === true;
        }
        return false;
    }, [auction?.status, isWinning]);

    const hasLostAuction = useMemo(() => {
        if (auction?.status === Auction.StatusEnum.Done) {
            return isWinning === false;
        }
        return false;
    }, [auction?.status, isWinning]);

    const pickupOption = useMemo(() => {
        if (hasAuction) {
            if (auction?.fulfillment === Auction.FulfillmentEnum.Pickup || auction?.fulfillment === Auction.FulfillmentEnum.PickupShip) {
                return auction.fulfillment;
            }
        }
        return undefined;
    }, [auction, hasAuction]);

    return {
        hasAuction,
        comingSoon,
        auctionType: auction?.type,
        hasUserAuctionAccess,
        isWinning,
        isLoosing,
        hasLostAuction,
        isOwner,
        auctionOver,
        suggestedBid,
        loading: placeBidMutation.isLoading,
        lastBid,
        endTime,
        auction,
        placeBid,
        hasWonAuction,
        pickupOption
    };
};

export default useAuction;
