import React from 'react';
import BCSpacer from 'public/assets/icons/chevron-right.svg';
import clsx from 'clsx';
import { Auction, Item } from '@/api';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';

type IProductBreadCrumbsProps = {
    className?: string;
    item?: Item
    isLoading?: boolean;
};

const ProductBreadCrumbs = (props: IProductBreadCrumbsProps) => {
    const { className, item, isLoading } = props;
    const hasActiveAuction = [Auction.StatusEnum.Open, Auction.StatusEnum.Extended, Auction.StatusEnum.Blind].includes(item?.current_auction?.status as Auction.StatusEnum);
    const filterString = hasActiveAuction ? '?has_auction=1' : '';
    return (
        <div className={clsx('justify-center md:justify-start items-center text-sm font-[500] text-blue8 font-secondary mb-4', className)}>
            {isLoading ? <Skeleton width={280} height={18} /> : (
                <>
                    <div className="inline-block"><Link prefetch={false} href={`/search${filterString}`}>{i18next.t('general.all')}</Link></div>
                    <div className="inline-block md:px-2 px-1"><BCSpacer className="pt-[2px] md:pt-0" /></div>
                    <div className="inline-block"><Link prefetch={false} href={`/category/${item?.category.slug}${filterString}`}><span>{item?.category.name}</span></Link></div>
                    <div className="inline-block md:px-2 px-1"><BCSpacer className="pt-[2px] md:pt-0" /></div>
                    <div className="inline-block"><Link prefetch={false} href={`/category/${item?.category.slug}?sub_categories=${item?.sub_category.slug}&${filterString.substring(1)}`}><span>{item?.sub_category.name}</span></Link></div>
                </>
            )}
        </div>
    );
};

export default ProductBreadCrumbs;
